import useCollapsibleTableRowState from 'modules/shared/hooks/useCollapsibleTableRowState';
import React, { Fragment, useEffect } from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import ContentPasteIcon from 'images/svgs/icon-content-paste.svg';
import Collapse from '@material-ui/core/Collapse';
import StatusIndicator from 'modules/shared/components/widgets/static/StatusIndicator.js';
import Button from 'modules/shared/components/inputs/Button';
import styled from 'styled-components';

import styles from './css/Reporting.css';
import ApplicationName from './Reporting/ApplicationName';

import { FEATURE_FLAGS } from 'conf';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';

const isWatchtowerEnabled = FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER;

const Wrapper = styled.div<{ $bgColor?: string }>`
  background-color: ${(props) => (props.$bgColor ? props.$bgColor : 'initial')};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 13px;
`;

const ViewGroupWrapper = styled.span`
  @media screen and (max-width: 991px) {
    display: none !important;
  }
`;

function ApplicationStatusIndicators({ id, statuses }) {
  return statuses.map((status) => (
    <StatusIndicator
      status={status.status}
      withStatusName={false}
      key={`indicator-${keyStatusName(status.name)}-${id}`}
    />
  ));
}

function getApplicationLimitColumn({ application, filterLimitType }) {
  if (filterLimitType === 'approved_limits') {
    return application.formattedAcceptedLimit;
  }

  return application.formattedRequestedLimit;
}

function getApplicationName(application) {
  const { soleTraderName, name } = application;
  if (FEATURE_FLAGS.FEATURE_FLAG_UPDATED_SOLE_TRADER && soleTraderName) {
    return soleTraderName;
  }

  return name;
}

function keyStatusName(statusName) {
  return statusName.toLowerCase().replace(/ /g, '-');
}

function ApplicationStatusDetails(props) {
  const {
    applicationId,
    applicationName,
    canReview,
    isSupplier,
    showViewGroup,
    statuses,
  } = props;

  const applicationStatus =
    statuses.find(({ name }) => name === 'Application') || {};
  const applicationIncomplete = ['not_started', 'in_progress'].includes(
    applicationStatus.status
  );
  const buttonText = applicationIncomplete ? 'Track' : 'Manage account';

  function renderStatus() {
    return statuses.map((status) => (
      <div
        className={styles.status_details_grid_table}
        key={`details-${keyStatusName(status.name)}-${applicationId}`}
      >
        <div className={styles.status_details_name}>{status.name}</div>
        <div>
          <StatusIndicator status={status.status} withStatusName={true} />
        </div>
        <div className={styles.mobile_invisible}>{status.started_on}</div>
        <div className={styles.mobile_invisible}>{status.completed_on}</div>
        <div className={styles.mobile_invisible}>{status.other}</div>
      </div>
    ));
  }

  const getWatchtowerUrl = () => {
    const encodedApplicationName = encodeURIComponent(applicationName);
    return `/dashboard/watchtower?key_word=${encodedApplicationName}&page=1&per=25&default_open`;
  };

  return (
    <Fragment>
      {renderStatus()}
      {/**
       * At the
       * moment, `isSupplier` is a string equivalent of a boolean value.
       * The `toString()` will future-proof this variable just in case later on
       * it will become a mixture of an actual boolean value and a string
       **/}
      {isSupplier.toString() === 'true' && (
        <div className={styles.review_button}>
          <ButtonsWrapper>
            <Button
              text={buttonText}
              link={
                canReview
                  ? `/dashboard/applications/${props.applicationId}`
                  : ''
              }
              disabled={!canReview}
            />
            {showViewGroup && (
              <ViewGroupWrapper>
                <Button
                  text={'View group'}
                  link={canReview ? getWatchtowerUrl() : ''}
                  disabled={!canReview}
                  white
                />
              </ViewGroupWrapper>
            )}
          </ButtonsWrapper>
          {!canReview && (
            <p className="mt-3">
              Please contact your team admin for authorising access to review
              virtual credit files.
            </p>
          )}
        </div>
      )}
    </Fragment>
  );
}

export const ApplicationRow = (props) => {
  const {
    application,
    current,
    index,
    isSupplier,
    searchParams,
    setCurrentIndex,
    showViewGroup: showViewGroupProp,
    watchtowerExclusions,
  } = props;

  const showViewGroup =
    isWatchtowerEnabled &&
    application.belongsToWatchtowerGroup(watchtowerExclusions) &&
    showViewGroupProp;

  const { icon, isOpen, onToggleIsOpen } = useCollapsibleTableRowState({
    useAddIcon: showViewGroup,
  });

  const onClick = () => {
    setCurrentIndex(index);
    onToggleIsOpen();
  };

  const isLiquidated = application.alertType === 'liquidation';
  const isDeregistered = application.alertType === 'deregistered';

  const wrapperClassName = [styles.grid_table];

  if (isDeregistered || isLiquidated) {
    wrapperClassName.push(styles.grid_table_alert);
  }

  useEffect(() => {
    if (!current && isOpen) onToggleIsOpen();
  }, [current]);

  const limit = getApplicationLimitColumn({
    application,
    filterLimitType: searchParams.limit_type,
  });

  const applicationName = getApplicationName(application);
  return (
    <div>
      <Wrapper
        $bgColor={
          isWatchtowerEnabled && application.getRowColor(watchtowerExclusions)
        }
        className={wrapperClassName.join(' ')}
      >
        <div className={styles.trading_name_col} onClick={onClick}>
          <div className={styles.icon_container}>{icon}</div>
          <div className={styles.trading_name}>
            <ApplicationName
              applicationName={applicationName}
              versionNumber={application.versionNumber}
              isFloating={application.isFloating}
            />
          </div>
          {application.callCentreActivity && (
            <PhoneIcon
              color={'primary'}
              style={{ fontSize: 20, marginLeft: 10, marginTop: 3 }}
            />
          )}
          {application.hasAccountMonitoring && (
            <PopperTooltip
              placement="top"
              title="Account monitoring (Account status/Review date)  apply. Go to VCF for more information"
            >
              <div className={styles.account_monitoring_icon_container}>
                <ContentPasteIcon className={styles.account_monitoring_icon} />
              </div>
            </PopperTooltip>
          )}
        </div>
        <div className={styles.cell}>
          <ApplicationStatusIndicators
            statuses={application.statusesFormatted}
            id={application.id}
          />
        </div>
        <div className={`${styles.cell} ${styles.mobile_invisible}`}>
          {limit}
        </div>
        <div className={`${styles.cell} ${styles.mobile_invisible}`}>
          {application.startedOn}
        </div>
        <div className={`${styles.cell} ${styles.mobile_invisible}`}>
          {application.completedOn}
        </div>
        <div className={`${styles.cell} ${styles.mobile_invisible}`}>
          {application.branchOrOwner}
        </div>
      </Wrapper>
      <Collapse in={current && isOpen}>
        <div className={styles.details}>
          {isWatchtowerEnabled && (
            <>
              <div className={styles.alert_text}>
                {application.getInternalWatchtowerAlertNames(
                  watchtowerExclusions
                )}
              </div>
              <div className={styles.alert_text}>
                {application.getExternalWatchtowerAlertNames(
                  watchtowerExclusions
                )}
              </div>
            </>
          )}

          {application.formattedAlertType && (
            <div className={styles.alert_text}>
              {`Alert - ${application.formattedAlertType}`}
            </div>
          )}
          <ApplicationStatusDetails
            applicationId={application.id}
            applicationName={application.name}
            canReview={application.canReview}
            isSupplier={isSupplier}
            showViewGroup={showViewGroup}
            statuses={application.statusesFormatted}
          />
        </div>
      </Collapse>
    </div>
  );
};
