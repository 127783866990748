/* eslint-disable max-lines */
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import HelpIcon from '@material-ui/icons/Help';
import PhoneIcon from '@material-ui/icons/Phone';
import { Skeleton } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import actioncable from 'actioncable';
import api from 'api';
import { FEATURE_FLAGS } from 'conf';
import debounce from 'debounce';
import IconApplicationActive from 'images/svgs/icon-application-active.svg';
import IconApplication from 'images/svgs/icon-application.svg';
import IconCreditControl from 'images/svgs/icon-credit-control.svg';
import IconDeleteArchiveDisabled from 'images/svgs/icon-delete-archive-disabled.svg';
import IconDeleteArchive from 'images/svgs/icon-delete-archive.svg';
import IconEmailLog from 'images/svgs/icon-email-log.svg';
import IconNotesActive from 'images/svgs/icon-notes-active.svg';
import IconNotes from 'images/svgs/icon-notes.svg';
import IconPDFDisabled from 'images/svgs/icon-pdf-disabled.svg';
import IconPDF from 'images/svgs/icon-pdf.svg';
import IconPPSRDisabled from 'images/svgs/icon-ppsr-disabled.svg';
import IconPPSR from 'images/svgs/icon-ppsr.svg';
import IconReallocateReassign from 'images/svgs/icon-reallocate-reassign.svg';
import watchtowerDefault from 'images/watchtower/default.png';
import watchtowerGreen from 'images/watchtower/green.gif';
import watchtowerRed from 'images/watchtower/red.gif';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import ApplicationModel from 'models/ApplicationModel';
import { isReviewerLevel } from 'models/ReviewModel';
import UserEntityLinkModel from 'models/UserEntityLinkModel';
import UserModel from 'models/UserModel';
import StatusIndicator from 'modules/new-applications/components/StatusIndicator';
import { TOOLTIP_TEXT } from 'modules/new-applications/constants';
import styles from 'modules/new-applications/css/common.css';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import useIsMobileScreenState, {
  MEDIA_QUERIES,
} from 'modules/new-applications/hooks/useIsMobileScreenState';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import Button from 'modules/shared/components/inputs/Button';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip.js';
import arrayFromNumber from 'modules/shared/helpers/arrayFromNumber';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import { userPagePermissions } from 'modules/shared/helpers/currentUserHelper';
import useCollapsibleContentState from 'modules/shared/hooks/useCollapsibleContentState';
import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { formatLocalTime } from 'utils/dateFormatter';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';

import AccountUpdateModal from './AccountUpdateModal';
import SidebarApplicationVersions from './SidebarApplicationVersions/SidebarApplicationVersions';
import AddonConfigModel from 'models/AddonConfigModel';
import {
  isBranchOrRegion,
  isFeatureAvailable,
} from 'modules/shared/helpers/headquarterDetect';

const DEBOUNCE_INTERVAL = 500;
const ALERT_AUTO_HIDE_DURATION = 8000;

export const PENDING_STATUS = [
  {
    label: 'Waiting on Applicant',
    value: 'waiting_on_applicant',
  },
  {
    label: 'Waiting on Initiator',
    value: 'waiting_on_initiator',
  },
  {
    label: 'Waiting on Cardholder',
    value: 'waiting_on_cardholder',
  },
  {
    label: 'Waiting on Signatory',
    value: 'waiting_on_signatory',
  },
  {
    label: 'Waiting on Guarantor',
    value: 'waiting_on_guarantor',
  },
  {
    label: 'Waiting on Direct debit',
    value: 'waiting_on_direct_debit',
  },
  {
    label: 'Financials - accountant',
    value: 'financials_accountant',
  },
  {
    label: 'Financials - customer',
    value: 'financials_customer',
  },
  {
    label: 'Waiting on Level 1 approver',
    value: 'waiting_on_level_1_approver',
  },
  {
    label: 'Waiting on Level 2 approver',
    value: 'waiting_on_level_2_approver',
  },
  {
    label: 'Waiting on Level 3 approver',
    value: 'waiting_on_level_3_approver',
  },
  {
    label: 'Waiting on Broker/Insurer',
    value: 'waiting_on_broker',
  },
  {
    label: 'Waiting on trade references',
    value: 'waiting_on_trade_references',
  },
  {
    label: 'Waiting on IUFs',
    value: 'waiting_on_iuf',
  },
  {
    label: 'Waiting on reviewer',
    value: 'waiting_on_reviewer',
  },
  {
    label: 'Waiting on sales team',
    value: 'waiting_on_sales_team',
  },
  {
    label: 'Waiting on supply chain',
    value: 'waiting_on_supply_chain',
  },
];

const brokerIsOn = (currentUser) =>
  get(currentUser, 'currentEntity.brokerConfig.onOff');

function useApplicationRecord(applicationId, currentUser, onSetAlert) {
  const [application, setApplication] = useState(new ApplicationModel());
  const accessToken = currentUser.accessToken;
  const entityId = get(currentUser, 'currentEntity.id');
  const pagePermissions = userPagePermissions(currentUser);
  const { isLoading, setIsLoading } = useIsLoadingState();

  const onFetchApplicationRecord = (onFetchCompleteCallback) => {
    (async () => {
      if (application.isNewRecord) {
        setIsLoading(true);
      }

      const loadedApplication =
        await ApplicationModel.fetchApplicationWithRelationships(
          {
            accessToken,
            entityId,
            id: applicationId,
            pagePermissions,
          },
          onSetAlert
        );

      if (onFetchCompleteCallback) {
        onFetchCompleteCallback();
      }

      setApplication(loadedApplication);
      loadedApplication.onPollCreditCheckRecords({
        accessToken,
        entityId,
        onRefreshCallback: setApplication,
      });

      if (
        !brokerIsOn(currentUser) &&
        PENDING_STATUS.map((x) => x.value).includes('waiting_on_broker')
      ) {
        for (let i = 0; i < PENDING_STATUS.length; i++) {
          if (PENDING_STATUS[i].value === 'waiting_on_broker') {
            PENDING_STATUS.splice(i, 1);
          }
        }
      }
      setIsLoading(false);
    })();
  };

  const onRefreshApplicationRecord = (application) => {
    setApplication(application);
    application.onPollCreditCheckRecords({
      accessToken,
      entityId,
      onRefreshCallback: onRefreshApplicationRecord,
    });
  };

  const onUpdateApplicationState = () => {
    application.clearCreditCheckPollingTimer();
    onRefreshApplicationRecord(application.cloneRecord());
  };

  useEffect(() => {
    onFetchApplicationRecord();

    return function cleanup() {
      application.clearCreditCheckPollingTimer();
    };
  }, [applicationId]);

  return {
    application,
    isLoading,
    onFetchApplicationRecord,
    onUpdateApplicationState,
  };
}

function alertPopupState() {
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  const onClose = () => {
    setAlertMessage('');
    setAlertType('success');
  };

  let alert = null;

  if (isPresent(alertMessage)) {
    alert = (
      <Snackbar
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        classes={{
          anchorOriginTopRight: styles.snackbar_top_right,
        }}
        open={Boolean(alertMessage)}
        autoHideDuration={ALERT_AUTO_HIDE_DURATION}
        onClose={onClose}
      >
        <Alert
          classes={{
            filledError: styles.alert_filled_error,
            filledSuccess: styles.alert_filled_success,
            root: styles.alert_root,
          }}
          severity={alertType}
          variant="filled"
          onClose={onClose}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    );
  }

  const onSetAlert = ({ message, type }) => {
    setAlertMessage(message);
    setAlertType(type);
  };

  return { alert, onSetAlert };
}

function extractApplicationIdFromProps(props) {
  return get(props, 'children.props.params.application_id', '');
}

function linkProperties(props, path) {
  const applicationId = extractApplicationIdFromProps(props);
  const pathname = get(props, 'children.props.location.pathname', '');
  const linkTo = `/dashboard/applications/${applicationId}${path}`;

  return {
    currentPath: pathname,
    isLinkActive: linkTo === pathname,
    linkTo,
  };
}

function notesLinkProperties(props) {
  return linkProperties(props, '/notes');
}

function TopBarLink(props) {
  const {
    currentUser,
    icon,
    iconDisabled,
    isDisabled,
    label,
    path,
    imageIconSrc,
    preventDisabledStyle,
  } = props;

  let Icon = icon;
  let linkClassName = styles.action;

  if (isDisabled) {
    Icon = iconDisabled;
    if (preventDisabledStyle) {
      linkClassName += ` ${styles.action_display_only}`;
    } else {
      linkClassName += ` ${styles.action_disabled}`;
    }
  }

  const { linkTo, isLinkActive } = linkProperties(props, path);

  if (isLinkActive) {
    linkClassName += ` ${styles.action_active}`;
  }

  const onClickLink = () => {
    mixpanel.track(`Topbar navigate to ${label}`, {
      'Entity ID': get(currentUser, 'currentEntity.id'),
      distinct_id: currentUser.id,
    });
  };

  return (
    <Link to={linkTo} className={linkClassName} onClick={onClickLink}>
      {!imageIconSrc && (
        <Icon className={styles.action_icon} width={40} height={40} />
      )}
      {!icon && (
        <img
          src={imageIconSrc}
          alt={label}
          height={40}
          width={40}
          className={styles.action_icon}
        />
      )}
      <div className={styles.action_label}>{label}</div>
    </Link>
  );
}

function TopbarApplicationStatus(props) {
  const { application } = props;

  let virtualCreditFileLabel;
  if (application.isAccepted) {
    virtualCreditFileLabel = (
      <div className={styles.virtual_credit_file_label}>
        Virtual credit file
      </div>
    );
  }

  let status = '';
  if (application.overallStatus !== 'Archived') {
    status = (
      <StatusIndicator
        withStatusName={true}
        status={application.overallStatus}
      />
    );
  }

  return (
    <div className={styles.status_label}>
      {status}
      {virtualCreditFileLabel}
    </div>
  );
}

function Topbar(props) {
  const {
    application,
    children,
    currentUser,
    useBulkApplicationDisplay,
    isWatchtowerActive,
    watchtowerExclusions,
  } = props;

  const isWatchtowerEnabled = isFeatureAvailable('watchtower');

  const alertIconToDisplay =
    application.getAlertIconToDisplay(watchtowerExclusions);

  return (
    <div className={styles.topbar}>
      <div className={styles.topbar_content}>
        <div className={styles.action_container}>
          {FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER &&
            isWatchtowerActive &&
            isWatchtowerEnabled && (
              <TopBarLink
                currentUser={currentUser}
                imageIconSrc={
                  !alertIconToDisplay
                    ? watchtowerDefault
                    : alertIconToDisplay === 'watching'
                    ? watchtowerGreen
                    : watchtowerRed
                }
                label="Watchtower"
                path="/watchtower"
                isDisabled={!isWatchtowerEnabled || isBranchOrRegion()}
                preventDisabledStyle={isBranchOrRegion()}
              >
                {children}
              </TopBarLink>
            )}

          <TopBarLink
            currentUser={currentUser}
            icon={IconNotes}
            label="Notes"
            path="/notes"
          >
            {children}
          </TopBarLink>

          <TopBarLink
            currentUser={currentUser}
            icon={IconDeleteArchive}
            iconDisabled={IconDeleteArchiveDisabled}
            label="Delete / Archive"
            path="/delete_archive"
          >
            {children}
          </TopBarLink>

          <TopBarLink
            currentUser={currentUser}
            icon={IconReallocateReassign}
            label="Reallocate / Reassign"
            path="/reallocate_reassign"
          >
            {children}
          </TopBarLink>

          {!useBulkApplicationDisplay && (
            <Fragment>
              <TopBarLink
                currentUser={currentUser}
                icon={IconPDF}
                label="PDF file"
                path="/pdf"
              >
                {children}
              </TopBarLink>

              {!FEATURE_FLAGS.FEATURE_FLAG_ACCOUNT_MONITORING && (
                <TopBarLink
                  currentUser={currentUser}
                  icon={IconCreditControl}
                  label="Credit control"
                  path="/credit_control"
                >
                  {children}
                </TopBarLink>
              )}
            </Fragment>
          )}

          <TopBarLink
            currentUser={currentUser}
            icon={IconPPSR}
            iconDisabled={IconPPSRDisabled}
            isDisabled={!application.ppsrEnabled}
            label="PPSR"
            path="/ppsr_register"
          >
            {children}
          </TopBarLink>

          {!useBulkApplicationDisplay && (
            <TopBarLink
              currentUser={currentUser}
              icon={IconEmailLog}
              label="History log"
              path="/history_log"
            >
              {children}
            </TopBarLink>
          )}
        </div>
        <TopbarApplicationStatus application={application} />
      </div>
    </div>
  );
}

function SidebarContent(props) {
  const { content, isLoading, isVisible, prefix } = props;

  if (!isVisible) {
    return null;
  }

  if (isLoading) {
    return (
      <Skeleton
        className="mb-4 mr-4"
        animation="wave"
        variant="rect"
        height="25"
      />
    );
  }

  if (isBlank(content)) {
    return null;
  }

  if (isPresent(prefix)) {
    return <div>{`${prefix}: ${content}`}</div>;
  }

  return <div>{content}</div>;
}

SidebarContent.defaultProps = {
  isVisible: true,
};

const sidebarReviewerContentMessage = (level, name) => {
  if (isReviewerLevel(level)) {
    return `R - ${name}`;
  }

  return `L${level} - ${name}`;
};

function SidebarReviewers(props) {
  const { application, isLoading } = props;

  return application.sortedReviewsByLevel.map((review, index) => (
    <SidebarContent
      key={`sidebar-content-reviewer-${index}`}
      content={sidebarReviewerContentMessage(review.level, review.reviewerName)}
      isLoading={isLoading}
    />
  ));
}

function getApplicationInfoLimit(application) {
  if (application.isAccepted) {
    return application.formattedFinalLimit;
  }

  return application.formattedTradeAccountLimit;
}

const DangerStatus = (props) => {
  const { isLoading, isVisible, label } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <SidebarContent
      content={
        <span className="has-text-danger has-text-weight-normal is-size-5">
          {label}
        </span>
      }
      isLoading={isLoading}
    />
  );
};

const getApplicationVersionLabel = (application) => (
  <span className={styles.header_version}>
    {` (Version ${application.versionNumber})`}
  </span>
);

function SidebarApplicationInfo(props) {
  const {
    application,
    isLoading,
    currentUser,
    dispatch,
    email_valid,
    useBulkApplicationDisplay,
    watchtowerExclusions,
  } = props;
  const { isOpen, onToggleIsOpen } = useCollapsibleContentState(true);
  const [isNewApplicationModalOpen, setNewApplicationModalState] =
    useState(false);

  const {
    noPermission,
    allVersions = [],
    isAccepted,
    latestAccountVersion,
  } = application;

  const isLatestApplication =
    latestAccountVersion === application.versionNumber;
  const userCanUpdateApplication =
    currentUser.isAdmin || currentUser.isApprover;
  const showUpdateApplication =
    (isAccepted || application.isBulkApplication) &&
    userCanUpdateApplication &&
    isLatestApplication;

  const userLevel = currentUser.hasOwnProperty('level')
    ? currentUser.level
    : currentUser.currentUserEntityLink.level;

  const canSendAccountUpdate = !(
    (currentUser.isAdmin || currentUser.isApprover) &&
    ![undefined, null].includes(userLevel)
  );

  const watchtowerAlertLabel =
    application.getWatchtowerAlertLabelForSidebar(watchtowerExclusions);
  const alertLabel =
    watchtowerAlertLabel ||
    `(${application.alertType} - ${application.alertDate})`;

  return (
    <div className={styles.sidebar_menu}>
      <PopperTooltip
        title={TOOLTIP_TEXT.applicationInfo}
        placement="bottom"
        isVisibleOnMobile={false}
      >
        <div className={styles.sidebar_menu_header} onClick={onToggleIsOpen}>
          Application info
          {getApplicationVersionLabel(application)}
          {application.callCentreActivity && (
            <PhoneIcon color={'primary'} style={{ marginLeft: 10 }} />
          )}
        </div>
      </PopperTooltip>
      {showUpdateApplication && (
        <React.Fragment>
          <PopperTooltip
            title={TOOLTIP_TEXT.accountUpdates}
            placement="top"
            isVisibleOnMobile={false}
          >
            <span>
              <Button
                disabled={canSendAccountUpdate}
                text="Account update"
                handleClick={() => setNewApplicationModalState(true)}
              />
            </span>
          </PopperTooltip>
          <AccountUpdateModal
            isOpen={isNewApplicationModalOpen}
            applicationTypeNames={
              currentUser.currentEntity.entityApplicationTypeNames
            }
            handleClose={() => setNewApplicationModalState(false)}
            supplierId={currentUser.currentEntity.id}
            currentApplicationId={application.id}
            invitesApi={api(
              'invites',
              currentUser.accessToken,
              currentUser.currentEntity.id
            )}
          />
        </React.Fragment>
      )}
      {!noPermission && (
        <Collapse in={isOpen} style={{ marginTop: '10px' }}>
          <DangerStatus
            isLoading={isLoading}
            isVisible={application.archived}
            label="Archived"
          />
          <SidebarContent
            content={application.formattedBusinessName}
            isLoading={isLoading}
          />
          <DangerStatus
            isLoading={isLoading}
            isVisible={watchtowerAlertLabel || application.alertType}
            label={alertLabel}
          />
          <SidebarContent
            content={get(application, 'defaultTradingName.tradingName')}
            isLoading={isLoading}
            prefix="Trading name"
          />
          <SidebarContent
            content={getApplicationInfoLimit(application)}
            isLoading={isLoading}
            isVisible={application.applicationType === 'credit'}
            prefix="Limit"
          />
          {useBulkApplicationDisplay ? (
            <Fragment>
              <SidebarContent
                content={
                  application.supplierName || application.supplierLegalName
                }
                isLoading={isLoading}
                prefix="Tier"
              />
              {application.hasReallocateHistory && (
                <Fragment>
                  <SidebarContent
                    content={
                      application.supplierName || application.supplierLegalName
                    }
                    isLoading={isLoading}
                    prefix="Reallocated to"
                  />
                  <SidebarContent
                    content={application.reassignedToName}
                    isLoading={isLoading}
                    prefix="Reassigned to"
                  />
                </Fragment>
              )}
              <SidebarContent
                content={'Rapid transfer'}
                isLoading={isLoading}
                prefix="Source"
              />
              <SidebarContent
                content={application.formattedImportDate}
                isLoading={isLoading}
                prefix="Import date"
              />
              <SidebarContent
                content={application.formattedActivatedDate || '-'}
                isLoading={isLoading}
                prefix="Activate date"
              />
            </Fragment>
          ) : (
            <Fragment>
              <SidebarContent
                content={application.initiatorName}
                isLoading={isLoading}
                prefix="Initiated by"
              />
              <SidebarContent
                content={application.initiatedTier}
                isLoading={isLoading}
                prefix="Initiated from"
              />
              <SidebarContent
                content={'1Centre'}
                isLoading={isLoading}
                prefix="Source"
              />
              {application.hasReallocateHistory && (
                <SidebarContent
                  content={
                    application.supplierName || application.supplierLegalName
                  }
                  isLoading={isLoading}
                  prefix="Reallocated to"
                />
              )}
              <SidebarContent
                content={application.reassignedToName}
                isLoading={isLoading}
                prefix="Reassigned to"
              />
            </Fragment>
          )}
          {application.submissionStatus !== 'not_started' && (
            <SidebarContent
              content={formatLocalTime(
                application.submissionStartedAt,
                'minute'
              )}
              isLoading={isLoading}
              prefix="Started at"
            />
          )}
          {application.submissionStatus === 'complete' && (
            <SidebarContent
              content={formatLocalTime(
                application.submissionModifiedAt,
                'minute'
              )}
              isLoading={isLoading}
              prefix="Completed at"
            />
          )}
          <SidebarContent
            content={application.formattedApprovedAt}
            isLoading={isLoading}
            prefix="Approved at"
          />
          {brokerIsOn(currentUser) && application.brokerEmail && (
            <SidebarContent
              content={application.formattedBrokerEmailSentAt}
              isLoading={isLoading}
              prefix="Broker/Insurer advised"
            />
          )}
          <SidebarContent
            content={application.iufApproval.approverName}
            isLoading={isLoading}
            prefix="IUF Approver"
          />
          {FEATURE_FLAGS.FEATURE_FLAG_ACCOUNT_MONITORING && (
            <SidebarContent
              content={application.accountStatusLabel}
              isLoading={isLoading}
              prefix="Account status"
            />
          )}
          <SidebarContent
            content={application.formattedReviewDate}
            isLoading={isLoading}
            prefix="Review date"
          />
          <SidebarReviewers
            application={application}
            isLoading={isLoading}
            currentUser={currentUser}
          />
        </Collapse>
      )}
    </div>
  );
}

function updateApplicationPendingState({
  application,
  currentUser,
  onUpdateApplicationState,
  pendingStates,
}) {
  const onSuccessCallback = () => {
    mixpanel.track('Set pending state', {
      Application: application.id,
      'Entity ID': get(currentUser, 'currentEntity.id'),
      distinct_id: currentUser.id,
      pending_status: pendingStates,
    });

    onUpdateApplicationState();
  };

  application.update({
    attributes: { pending_states: pendingStates },
    currentUser,
    onSuccessCallback,
  });
}

function usePendingStatusState(props) {
  const { application, currentUser, onUpdateApplicationState } = props;
  const applicationPendingStates = application.pendingStates;

  const [pendingStates, setPendingStates] = useState(
    applicationPendingStates || {}
  );

  const debouncedUpdateApplication = useRef(
    debounce(updateApplicationPendingState, DEBOUNCE_INTERVAL)
  ).current;

  const onClickPendingState = (pendingStateType, isChecked) => {
    const newPendingStates = {
      ...pendingStates,
      [pendingStateType]: isChecked,
    };
    setPendingStates(newPendingStates);
    debouncedUpdateApplication({
      application,
      currentUser,
      onUpdateApplicationState,
      pendingStates: newPendingStates,
    });
  };

  useEffect(() => {
    setPendingStates(applicationPendingStates || {});
  }, [applicationPendingStates]);

  return { onClickPendingState, pendingStates };
}

function PendingState(props) {
  const {
    label,
    noPermission,
    onClickPendingState,
    pendingStates,
    pendingStateType,
  } = props;
  const isChecked = pendingStates[pendingStateType] || false;

  const onChange = (event) => {
    onClickPendingState(pendingStateType, get(event, 'target.checked'));
  };

  return (
    <div>
      <FormControlLabel
        classes={{
          label: styles.sidebar_menu_checkbox_label,
        }}
        control={
          <Checkbox
            className={styles.sidebar_menu_checkbox}
            checked={isChecked}
            onChange={onChange}
            disabled={noPermission}
          />
        }
        label={label}
      />
    </div>
  );
}

function SidebarPendingStatusHeader(props) {
  const { isOpen, noPermission, onClickPendingState, pendingStates } = props;
  const ArrowIcon = isOpen ? ArrowDropUpIcon : ArrowDropDownIcon;

  const defaultHeader = (
    <PopperTooltip
      title={TOOLTIP_TEXT.pendingStatus}
      placement="bottom"
      isVisibleOnMobile={false}
    >
      <div className="is-flex is-justify-content-space-between is-align-items-center">
        <div>Pending status</div>
        <ArrowIcon color="primary" />
      </div>
    </PopperTooltip>
  );

  if (isOpen) {
    return defaultHeader;
  }

  const selectedLabels = PENDING_STATUS.filter(
    (pendingStatus) => pendingStates[pendingStatus.value]
  ).map((pendingStatus) => (
    <PendingState
      key={`pending-state-checkbox-${pendingStatus.value}`}
      label={pendingStatus.label}
      noPermission={noPermission}
      pendingStates={pendingStates}
      pendingStateType={pendingStatus.value}
      onClickPendingState={onClickPendingState}
    />
  ));

  if (isBlank(selectedLabels)) {
    return defaultHeader;
  }

  return (
    <div>
      {defaultHeader}
      <div>{selectedLabels}</div>
    </div>
  );
}

function SidebarPendingStatus(props) {
  const { application } = props;
  const { isOpen, onToggleIsOpen } = useCollapsibleContentState();
  const { onClickPendingState, pendingStates } = usePendingStatusState(props);
  const { noPermission } = application;

  const pendingStateCheckboxes = PENDING_STATUS.map((pendingStatus) => (
    <PendingState
      key={`pending-state-checkbox-${pendingStatus.value}`}
      label={pendingStatus.label}
      pendingStates={pendingStates}
      pendingStateType={pendingStatus.value}
      onClickPendingState={onClickPendingState}
      noPermission={noPermission}
    />
  ));

  return (
    <div className={styles.sidebar_menu}>
      <div className={styles.sidebar_menu_header} onClick={onToggleIsOpen}>
        <SidebarPendingStatusHeader
          isOpen={isOpen}
          onClickPendingState={onClickPendingState}
          pendingStates={pendingStates}
          noPermission={noPermission}
        />
      </div>

      <Collapse in={isOpen}>{pendingStateCheckboxes}</Collapse>
    </div>
  );
}

function ActionRequired(props) {
  const { isVisible, linkTo, onClickLink, tooltip } = props;

  if (!isVisible) {
    return null;
  }

  const icon = (
    <div className={styles.action_required_icon}>
      <Link to={linkTo} onClick={onClickLink}>
        <HelpIcon color="error" fontSize="small" />
      </Link>
    </div>
  );

  if (isPresent(tooltip)) {
    return (
      <Tooltip
        title={
          <span className={styles.action_required_tooltip}>{tooltip}</span>
        }
        placement="left"
        arrow
      >
        {icon}
      </Tooltip>
    );
  }

  return icon;
}

function SidebarLink(props) {
  const {
    actionRequiredTooltip,
    content,
    currentUser,
    isActionRequired,
    isLoading,
    isOpen,
    isVisible,
    label,
    onClickSidebarLink,
    path,
    status,
    tooltip,
  } = props;
  const { currentPath, linkTo, isLinkActive } = linkProperties(props, path);

  if (!isVisible) {
    return null;
  }

  if (isLoading) {
    return (
      <Skeleton
        className="mb-4 mr-4"
        animation="wave"
        variant="rect"
        height="25"
        style={{ marginLeft: '30px' }}
      />
    );
  }

  let sectionClassName = styles.sidebar_menu_application_section;
  if (isLinkActive) {
    sectionClassName += ` ${styles.sidebar_menu_application_section_active}`;
  }

  if (isLinkActive && isOpen) {
    sectionClassName += ` ${styles.sidebar_menu_application_section_open}`;
  }

  let visibleContent = null;
  if (isLinkActive && isOpen && isPresent(content)) {
    visibleContent = (
      <div className={styles.sidebar_menu_application_content}>{content}</div>
    );
  }

  const onClickLink = () => {
    mixpanel.track(`Sidebar navigate to ${label}`, {
      'Entity ID': get(currentUser, 'currentEntity.id'),
      distinct_id: currentUser.id,
    });

    if (isPresent(content)) {
      onClickSidebarLink(currentPath, linkTo);
    }
  };

  let statusAndLabel = (
    <div className={styles.sidebar_menu_application_status}>
      <div className={styles.sidebar_menu_application_status_content}>
        <StatusIndicator status={status} />
        <span className={styles.sidebar_menu_application_section_link}>
          <Link to={linkTo} onClick={onClickLink}>
            {label}
          </Link>
        </span>
      </div>
      <ActionRequired
        isVisible={isActionRequired}
        linkTo={linkTo}
        onClickLink={onClickLink}
        tooltip={actionRequiredTooltip}
      />
    </div>
  );

  if (tooltip) {
    statusAndLabel = (
      <PopperTooltip
        title={tooltip}
        placement="bottom"
        isVisibleOnMobile={false}
      >
        {statusAndLabel}
      </PopperTooltip>
    );
  }

  return (
    <div className={sectionClassName}>
      {statusAndLabel}
      {visibleContent}
    </div>
  );
}

SidebarLink.defaultProps = {
  actionRequiredTooltip: null,
  isActionRequired: false,
  isVisible: true,
  label: '',
};

function SidebarLinks(props) {
  const { application, isLoading } = props;

  let businessDetailsLabel = 'Business details';
  if (application.legalType === 'personal') {
    businessDetailsLabel = 'Customer details';
  }

  return (
    <Fragment>
      <SidebarLink
        path=""
        status={application.submissionStatus}
        label={businessDetailsLabel}
        isLoading={isLoading}
        {...props}
      />

      <SidebarLink
        path="/trading_details"
        status={application.submissionStatus}
        label="Trading details"
        isVisible={application.legalType === 'company'}
        isLoading={isLoading}
        {...props}
      />

      <SidebarLink
        path="/additional"
        status={application.submissionStatus}
        label="Additional"
        isVisible={isPresent(application.additionalsAddonRule)}
        isLoading={isLoading}
        {...props}
      />

      <SidebarLink
        path="/financials"
        status={application.submissionStatus}
        label="Financials"
        isVisible={isPresent(application.financialsAddonRule)}
        isLoading={isLoading}
        {...props}
      />

      <SidebarLink
        path="/cards"
        status={application.cardholderStatus}
        label="Cards"
        isVisible={application.requiresCardholders || false}
        isLoading={isLoading}
        {...props}
      />

      <SidebarLink
        path="/direct_debit"
        status={application.paperlessStatus}
        label="Direct debit"
        isVisible={application.requiresPaperless || false}
        isLoading={isLoading}
        {...props}
      />

      <SidebarLink
        path="/guarantors"
        status={application.guarantorStatus}
        label="Guarantor"
        isVisible={
          (application.legalType === 'company' &&
            application.requiresGuarantees) ||
          application.requiresTrustGuarantees ||
          false
        }
        isLoading={isLoading}
        {...props}
      />
      <SidebarLink
        path="/signatories"
        status={application.signatoryStatus}
        label="Signatory"
        isVisible={
          (application.requiresSignatories &&
            !application.requiresTrustGuarantees) ||
          false
        }
        isLoading={isLoading}
        {...props}
      />

      <SidebarLink
        path="/authorised_applicant"
        status={application.submissionStatus}
        label="Authorised applicant"
        isLoading={isLoading}
        {...props}
      />

      <SidebarLink
        path="/trade_references"
        status={application.tradeReferenceCheckStatus}
        label="Reference checks"
        isActionRequired={application.isTradeReferenceCheckActionRequired}
        isVisible={application.isTradeReferencesVisible}
        isLoading={isLoading}
        {...props}
      />

      <SidebarLink
        path="/credit_checks"
        status={application.creditCheckStatus}
        label="Credit checks"
        isActionRequired={application.isCreditCheckActionRequired}
        isVisible={application.isCreditCheckRequired || false}
        isLoading={isLoading}
        {...props}
      />

      <SidebarLink
        path="/identification_checks"
        status={application.identificationCheckStatus}
        label="eSignatures"
        isActionRequired={application.isIdentificationCheckStatusRequired}
        isLoading={isLoading}
        tooltip={TOOLTIP_TEXT.indentificationCheck}
        {...props}
      />

      <SidebarLink
        path="/internal_use_fields"
        status={application.internalUseFieldsStatus}
        label="IUF"
        isVisible={isPresent(application.iufAddonRule)}
        isLoading={isLoading}
        {...props}
      />

      <SidebarLink
        path="/limit_and_approvals"
        status={application.finalApprovalStatus}
        label="Limit & approvals"
        actionRequiredTooltip="Action required"
        isActionRequired={!application.isViewOnly}
        isVisible={application.isLimitApprovalVisible}
        isLoading={isLoading}
        tooltip={TOOLTIP_TEXT.limitsNApprovals}
        {...props}
      />

      <SidebarLink
        path="/loaded_in_system"
        status={application.loadedInSystemStatus}
        label="Loaded in system"
        isVisible={application.isLoadedInSystemVisible}
        isLoading={isLoading}
        {...props}
      />
    </Fragment>
  );
}

function SidebarApplicationSections(props) {
  const { application, isLoading } = props;

  const { noPermission } = application;

  return (
    <div className={styles.sidebar_menu}>
      {!noPermission &&
        (application.isBulkApplication ? (
          <SidebarLink
            path=""
            status={application.submissionStatus}
            label="Customer details"
            isLoading={isLoading}
            {...props}
          />
        ) : (
          <SidebarLinks
            application={application}
            isLoading={isLoading}
            {...props}
          />
        ))}
    </div>
  );
}

function MobileMainBody(props) {
  const { application, content, isLoading } = props;
  const { isLinkActive: isNotesPageActive } = notesLinkProperties(props);

  if (isLoading || isNotesPageActive) {
    return <div className={styles.mobile_main}>{content}</div>;
  }

  const { isOpen, onToggleIsOpen, setIsOpen } =
    useCollapsibleContentState(false);

  const onClickSidebarLink = (currentPath, nextLinkTo) => {
    if (currentPath === nextLinkTo) {
      onToggleIsOpen();
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className={styles.mobile_main}>
      <FixedContent
        header={
          <React.Fragment>
            {`Review progress for ${application.formattedBusinessName}`}
            {getApplicationVersionLabel(application)}
          </React.Fragment>
        }
      >
        <SidebarLinks
          application={application}
          content={content}
          isLoading={isLoading}
          isOpen={isOpen}
          onClickSidebarLink={onClickSidebarLink}
          {...props}
        />
      </FixedContent>
    </div>
  );
}

function MainBody(props) {
  const { isMobileScreen } = useContext(MobileScreenContext);
  const {
    application,
    currentUser,
    isLoading,
    onFetchApplicationRecord,
    onSetAlert,
    onUpdateApplicationState,
    userEntityLink,
    watchtowerExclusions,
  } = props;
  // const {
  //   draftNote,
  //   onSetDraftNoteAttributes,
  //   resetDraftNote,
  // } = useDraftNote();

  const { noPermission } = application;

  let content;
  if (isLoading) {
    content = (
      <FixedContent>
        {arrayFromNumber(6, (number) => (
          <Skeleton
            key={`vcf-content-skeleton-${number}`}
            animation="wave"
            className={`mb-4 ${!isMobileScreen && 'mr-6'}`}
            height={25}
            variant="rect"
          />
        ))}
      </FixedContent>
    );
  } else {
    content = React.Children.map(props.children, (child) =>
      React.cloneElement(child, {
        application,
        currentUser,
        onFetchApplicationRecord,
        onSetAlert,
        onUpdateApplicationState,
        userEntityLink,
        watchtowerExclusions,
      })
    );
  }

  if (isMobileScreen) {
    return (
      <MobileMainBody
        application={application}
        content={content}
        isLoading={isLoading}
        {...props}
      />
    );
  }

  if (noPermission) {
    content = (
      <FixedContent header="Oops! You do not have access.">
        <GridContent>
          <p>
            Please contact your team admin for authorising access to review
            virtual credit files.
          </p>
        </GridContent>
      </FixedContent>
    );
  }

  return <div className={styles.main}>{content}</div>;
}

function MobileBottomMenuLink(props) {
  const { currentUser, icon, iconActive, iconProps, label, path } = props;

  let Icon = icon;
  let linkClassName = styles.mobile_bottom_menu_action;

  const { currentPath, linkTo, isLinkActive } = linkProperties(props, path);

  let isActive = false;
  if (path === '/notes') {
    isActive = isLinkActive;
  } else {
    const { linkTo: notesLinkTo } = notesLinkProperties(props);
    isActive = currentPath !== notesLinkTo;
  }

  if (isActive) {
    Icon = iconActive;
    linkClassName += ` ${styles.mobile_bottom_menu_action_active}`;
  }

  const onClickLink = () => {
    mixpanel.track(`Mobile bottom menu navigate to ${label}`, {
      'Entity ID': get(currentUser, 'currentEntity.id'),
      distinct_id: currentUser.id,
    });
  };

  return (
    <Link to={linkTo} className={linkClassName} onClick={onClickLink}>
      <Icon
        width={iconProps.width}
        height={iconProps.height}
        style={{
          margin: iconProps.margin,
        }}
      />
      <div>{label}</div>
    </Link>
  );
}

MobileBottomMenuLink.defaultProps = {
  iconProps: {
    height: 50,
    margin: 0,
    width: 50,
  },
};

function MobileBottomMenuAction(props) {
  const { currentUser, icon, iconDisabled, isDisabled, label, onClick } = props;

  let Icon = icon;
  let linkClassName = styles.mobile_bottom_menu_action;

  if (isDisabled) {
    Icon = iconDisabled;
    linkClassName += ` ${styles.mobile_bottom_menu_action_disabled}`;
  }

  const onClickAction = () => {
    if (isDisabled) {
      return;
    }

    mixpanel.track(`Mobile bottom menu navigate to ${label}`, {
      'Entity ID': get(currentUser, 'currentEntity.id'),
      distinct_id: currentUser.id,
    });

    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={linkClassName} onClick={onClickAction}>
      <Icon width={50} height={50} />
      <div>{label}</div>
    </div>
  );
}

function MobileBottomMenu(props) {
  const { application, children, currentUser } = props;

  return (
    <div className={styles.mobile_bottom_menu}>
      <MobileBottomMenuLink
        currentUser={currentUser}
        icon={IconApplication}
        iconActive={IconApplicationActive}
        iconProps={{
          height: 32,
          margin: 10,
          width: 32,
        }}
        label="Application"
        path=""
      >
        {children}
      </MobileBottomMenuLink>

      <MobileBottomMenuLink
        currentUser={currentUser}
        icon={IconNotes}
        iconActive={IconNotesActive}
        label="Notes"
        path="/notes"
      >
        {children}
      </MobileBottomMenuLink>

      <MobileBottomMenuAction
        currentUser={currentUser}
        icon={IconPDF}
        iconDisabled={IconPDFDisabled}
        isDisabled={!application.isAccepted}
        label="PDF file"
        onClick={() => application.downloadPDF({ currentUser })}
      >
        {children}
      </MobileBottomMenuAction>
    </div>
  );
}

function initializeActionCable(props) {
  const { application, currentUser, onFetchApplicationRecord } = props;
  const onReceiveData = () => {
    // eslint-disable-next-line no-console
    console.info('WS receiving data');
    onFetchApplicationRecord();
  };

  useEffect(() => {
    if (application.isPersisted) {
      const consumer = actioncable.createConsumer(
        `${window._env_.API_URL}/cable`
      );
      consumer.subscriptions.create(
        {
          channel: 'ApplicationsChannel',
          id: application.id,
        },
        {
          // eslint-disable-next-line no-console
          connected: () => console.info('WS connected'),
          // eslint-disable-next-line no-console
          disconnected: () => console.info('WS disconnected'),
          received: onReceiveData,
        }
      );
    }
  }, [application.isPersisted, currentUser.accessToken]);
}

export const MobileScreenContext = createContext({ isMobileScreen: false });

function Application(props) {
  const { currentUser } = props;
  const applicationId = extractApplicationIdFromProps(props);
  const { isMobileScreen } = useIsMobileScreenState(
    MEDIA_QUERIES.max_width_991
  );
  const { alert, onSetAlert } = alertPopupState();
  const {
    application,
    isLoading,
    onFetchApplicationRecord,
    onUpdateApplicationState,
  } = useApplicationRecord(applicationId, currentUser, onSetAlert);

  initializeActionCable({
    application,
    currentUser,
    onFetchApplicationRecord,
  });

  const [modules, setModules] = useState({});

  const [userEntityLink, setUserEntityLink] = useState();

  useEffect(() => {
    (async () => {
      if (isPresent(application.supplierId)) {
        const uel = await UserEntityLinkModel.retrieveByUserAndEntity({
          accessToken: currentUser.accessToken,
          entityId: application.supplierId,
          userId: currentUser.id,
        });

        setUserEntityLink(uel || currentUser.currentUserEntityLink);
      }
    })();
  }, [currentUser.accessToken, currentUser.id, application.supplierId]);

  useEffect(() => {
    AddonConfigModel.fetchAddonConfigByAddonType({
      addonType: 'watchtower_module',
      entityId: currentUser.currentEntity.id,
    }).then((response) => setModules({ watchtower: response[0] }));
  }, []);

  const useBulkApplicationDisplay = FEATURE_FLAGS.FEATURE_FLAG_RAPID_TRANSFER
    ? application.isBulkApplication
    : false;

  const watchtowerModuleData = modules.watchtower;
  const isWatchtowerActive =
    watchtowerModuleData && typeof watchtowerModuleData.active === 'boolean'
      ? watchtowerModuleData.active
      : true;

  const watchtowerExclusions = get(
    watchtowerModuleData,
    'attributes.history_version.data[0].attributes.config.internal_alerts.application_status_exclusions',
    []
  );

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <MobileScreenContext.Provider value={{ isMobileScreen }}>
        <div className={styles.container}>
          {alert}
          <Topbar
            application={application}
            useBulkApplicationDisplay={useBulkApplicationDisplay}
            isWatchtowerActive={isWatchtowerActive}
            watchtowerExclusions={watchtowerExclusions}
            {...props}
          />

          <div className={styles.sidebar}>
            <SidebarApplicationInfo
              application={application}
              isLoading={isLoading}
              useBulkApplicationDisplay={useBulkApplicationDisplay}
              watchtowerExclusions={watchtowerExclusions}
              {...props}
            />
            <SidebarApplicationVersions
              styles={styles}
              applicationVersions={application.allVersions}
              currentApplicationId={application.id}
            />
            {!useBulkApplicationDisplay && (
              <SidebarPendingStatus
                application={application}
                isLoading={isLoading}
                onUpdateApplicationState={onUpdateApplicationState}
                {...props}
              />
            )}
            <SidebarApplicationSections
              application={application}
              isLoading={isLoading}
              useBulkApplicationDisplay={useBulkApplicationDisplay}
              {...props}
            />
          </div>

          <MainBody
            application={application}
            isLoading={isLoading}
            userEntityLink={userEntityLink}
            onSetAlert={onSetAlert}
            onFetchApplicationRecord={onFetchApplicationRecord}
            onUpdateApplicationState={onUpdateApplicationState}
            watchtowerExclusions={watchtowerExclusions}
            {...props}
          />

          <MobileBottomMenu application={application} {...props} />
        </div>
      </MobileScreenContext.Provider>
    </MuiThemeProvider>
  );
}

export default connect((state) => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
    email_valid: state.cob_business.email_valid,
    email_validating: state.cob_business.email_validating,
  };
})(Application);
