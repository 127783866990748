import { TERMS_AND_CONDITIONS, THIRD_PARTY_CONSENT_LABEL } from 'constants';
import {
  readyForSupplierTerms,
  updateAuthorisation,
  updateApplicationComplete,
  createAuthorityDeclaration,
} from 'modules/consumer-onboarding/actions/review';

import Typography from '@material-ui/core/Typography';
import Button from 'modules/shared/components/inputs/Button';
import Radiobox from 'modules/shared/components/inputs/Radiobox';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import TextInput from 'modules/shared/components/inputs/TextInput';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import PdfReader from 'modules/shared/components/widgets/static/pdfReader';
import GuarantorConfirmModal from 'modules/shared/components/widgets/static/GuarantorConfirmModal';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { customTermsUrl } from 'utils/extractAttachmentUrl';
import flattenArray from 'utils/flattenArray';
import isPresent from 'utils/isPresent';
import { areStringsEqual } from 'utils';
import styles from './css/Review.css';
import * as yup from 'yup';
import { getIsIdentificationDisclaimerVisible } from '../utils';
import get from 'lodash.get';
import buildNoIdentificationSignatureAttributes from 'utils/anti-fraud/buildNoIdentificationSignatureAttributes';
import getApplicableAntiFraudRulesForApplicationFlow from 'utils/anti-fraud/getApplicableAntiFraudRulesForApplicationFlow';
import AntiFraudAddonVersionModel from 'models/AntiFraudAddonVersionModel';
import ApplicationModel from 'models/ApplicationModel';
import ReviewTerms from './ReviewTerms';
import { WatchtowerBlockMessage } from './WatchtowerBlockMessage';
import styled from 'styled-components';
import { FEATURE_FLAGS } from 'conf';

const SectionTitle = ({ title, style }) => (
  <Typography variant="h5" gutterBottom style={style}>
    {title}
  </Typography>
);

const RadioButtonContainer = styled.div`
  margin-left: -5px;
  margin-top: 5px;
`;

const isNewTermsAndConditionsEnabled = FEATURE_FLAGS.FEATURE_FLAG_UPDATED_TNCS;
const isGuarantorUpdatedTncsEnabled =
  FEATURE_FLAGS.FEATURE_FLAG_GUARANTOR_TNC_UX_UPDATE;

const CHECKBOX_TERMS_BY_SIGNATORY = {
  antiFraud: ['antiFraudIdHoldInformation'],
  guarantor: isNewTermsAndConditionsEnabled
    ? ['guarantor_liability', 'guarantor_capacity']
    : ['guarantor'],
  paperless: ['paperless'],
  supplier: [
    'supplier',
    'supplierCorrectInformation',
    'supplierLegalAge',
    'supplierDiscloseInformation',
    'thirdPartyCheckConsent',
  ],
};

const retrieveNameComponents = ({
  cardholder,
  guarantor,
  identity,
  signatory,
}) => {
  if (guarantor) {
    return { firstName: guarantor.first_name, lastName: guarantor.last_name };
  }

  if (signatory) {
    return {
      firstName: get(signatory, 'attributes.first_name'),
      lastName: get(signatory, 'attributes.last_name'),
    };
  }

  if (cardholder) {
    return {
      firstName: get(cardholder, 'attributes.firstName'),
      lastName: get(cardholder, 'attributes.lastName'),
    };
  }

  return { firstName: identity.first_name, lastName: identity.last_name };
};

const VALIDATION_SCHEMA = yup.object().shape({
  firstName: yup
    .string()
    .required('You must enter your first name')
    .test(
      'compare-first-name',
      'Incorrect first name',
      function test(firstName) {
        const referenceFirstName = this.options.context.referenceFirstName;

        if (isPresent(firstName) && isPresent(referenceFirstName)) {
          return areStringsEqual(referenceFirstName, firstName);
        }

        return true;
      }
    ),
  lastName: yup
    .string()
    .required('You must enter your last name')
    .test('compare-first-name', 'Incorrect last name', function test(lastName) {
      const referenceLastName = this.options.context.referenceLastName;

      if (isPresent(lastName) && isPresent(referenceLastName)) {
        return areStringsEqual(referenceLastName, lastName);
      }

      return true;
    }),
  guarantorApproved: yup
    .string()
    .nullable()
    .test(
      'guarantor-actioned',
      'You must choose whether to guarantee this account or not.',
      function test(guarantorAction) {
        if (!this.options.context.isGuarantor) {
          return true;
        }

        return isPresent(guarantorAction);
      }
    ),
});

const ReviewSupplierTerms = createClass({
  handleConfirm() {
    const { applicant_guarantor } = this.props;
    const { guarantorApproved } = this.state;
    if (this.completed()) {
      // All required fields are filled
      if (applicant_guarantor && guarantorApproved === 'Yes') {
        // Ask for guarantor confirmation
        this.setState({ isGuarantorConfirmModalVisible: true });
      } else {
        this.agree();
      }
    } else {
      this.checkValids();
    }
  },

  agree() {
    const {
      authorisation_id,
      combine,
      dispatch,
      referenceFirstName,
      referenceLastName,
      requiresIdentificationSection,
    } = this.props;
    const {
      guarantorApproved,
      first_name,
      last_name,
      isGuarantorConfirmModalVisible,
    } = this.state;

    if (isGuarantorConfirmModalVisible) {
      this.hideGuarantorConfirmModal();
    }

    let attributes = {};

    // If ID section is not required, pass in the names provided in the
    // e-signature section to be saved in the Signature record
    if (!requiresIdentificationSection) {
      const signatureAttributes = buildNoIdentificationSignatureAttributes({
        firstName: referenceFirstName || first_name,
        lastName: referenceLastName || last_name,
      });

      attributes['signature'] = signatureAttributes;
    }

    if (combine) {
      attributes = {
        cardholder_approved: true,
        guarantor_approved: guarantorApproved === 'Yes',
        payment_approved: true,
        signatory_approved: true,
        ...attributes,
      };
    }

    if (isPresent(attributes)) {
      dispatch(updateAuthorisation(authorisation_id, attributes));
    }
    this.agree1ctr();
  },

  checkValid(id, value) {
    const {
      referenceFirstName,
      referenceLastName,
      applicant_guarantor: isGuarantor,
    } = this.props;
    const { errors } = this.state;

    try {
      VALIDATION_SCHEMA.validateSyncAt(
        id,
        { [id]: value },
        { context: { isGuarantor, referenceFirstName, referenceLastName } }
      );

      this.setState({ errors: { ...errors, [id]: '' } });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        this.setState({ errors: { ...errors, [error.path]: error.message } });
      }
    }
  },

  checkValids() {
    if (!this.state.hasScrolled) {
      this.setState({ isTsCsTooltipActive: true });
    }

    if (!this.state.hasCheckedAllBoxes) {
      this.setState({ showBoxesHint: true });
    }

    const {
      first_name: firstName,
      guarantorApproved,
      last_name: lastName,
    } = this.state;
    const {
      applicant_guarantor: isGuarantor,
      referenceFirstName,
      referenceLastName,
    } = this.props;

    try {
      VALIDATION_SCHEMA.validateSync(
        {
          firstName,
          guarantorApproved,
          lastName,
        },
        {
          abortEarly: false,
          context: {
            isGuarantor,
            referenceFirstName,
            referenceLastName,
          },
        }
      );
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const { errors } = this.state;

        for (const innerError of error.inner) {
          errors[innerError.path] = innerError.message;
        }

        this.setState({ errors });
      }
    }
  },

  completed() {
    const { applicant_guarantor, combine } = this.props;
    const {
      hasScrolled,
      first_name,
      last_name,
      errors,
      guarantorApproved,
      hasCheckedAllBoxes,
    } = this.state;

    if (!hasScrolled) {
      return false;
    }

    if (!hasCheckedAllBoxes) {
      return false;
    }

    if (applicant_guarantor && !guarantorApproved) {
      return false;
    }

    return (
      first_name && last_name && !errors['firstName'] && !errors['lastName']
    );
  },

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(readyForSupplierTerms());
  },

  getInitialState() {
    return {
      antiFraudIdHoldInformation: false,
      errors: {},
      first_name: '',
      guarantor: false,
      guarantorApproved: null,
      hasCheckedAllBoxes: false,
      hasScrolled: true,
      isTsCsTooltipActive: false,
      last_name: '',
      paperless: false,
      supplierDiscloseInformation: false,
      showBoxesHint: false,
      supplier: false,
      supplierCorrectInformation: false,
      thirdPartyCheckConsent: false,
      supplierLegalAge: false,
      agreements: [
        { id: 1, agreed: false },
        { id: 2, agreed: false },
      ],
      isAgreeTooltipActive: false,
      isGuarantorConfirmModalVisible: false,
    };
  },

  toggleAgreement(i) {
    let agreements = this.state.agreements.map(function (a) {
      return {
        id: a.id,
        agreed: a.id === i ? !a.agreed : a.agreed,
      };
    });

    this.setState({ agreements });
    this.setState({ isAgreeTooltipActive: false });
  },

  completed1ctr() {
    let agreed = true;
    for (let i = 0; i < this.state.agreements.length; i++) {
      agreed = agreed && this.state.agreements[i].agreed;
    }
    return agreed;
  },

  agree1ctr() {
    const { dispatch, application, current_entity, submitting } = this.props;
    if (this.completed1ctr() && application && !submitting) {
      dispatch(createAuthorityDeclaration(application.id));
      dispatch(
        updateApplicationComplete(
          current_entity.id,
          `/register/consumer/${application.id}/review/complete`
        )
      );
    } else {
      this.setState({ isAgreeTooltipActive: true });
    }
  },

  handleBlur(event) {
    if (event.hasOwnProperty('target')) {
      this.checkValid(event.target.id, event.target.value, event.target);
    }
  },

  handleChange(event) {
    if (event.hasOwnProperty('target')) {
      this.checkValid(event.target.id, event.target.value);

      switch (event.target.id) {
        case 'firstName':
          this.setState({ first_name: event.target.value });
          break;
        case 'lastName':
          this.setState({ last_name: event.target.value });
          break;
      }
    }
  },

  handleGuaranteed(event) {
    if (isGuarantorUpdatedTncsEnabled && isNewTermsAndConditionsEnabled) {
      if (this.validateAllBoxes('guarantorSection', event.target.value)) {
        this.setState({ hasCheckedAllBoxes: true });
        this.setState({ showBoxesHint: false });
      } else {
        this.setState({ hasCheckedAllBoxes: false });
      }
    }
    this.setState({ guarantorApproved: event.target.value });
    this.checkValid('guarantorApproved', event.target.value);
  },

  hasCustomGuarantorTerms() {
    const { application } = this.props;
    return (
      application.attributes.uses_custom_guarantor_terms &&
      customTermsUrl(application.attributes.custom_guarantor_terms).url
    );
  },

  hasCustomPaperlessTerms() {
    const { application } = this.props;
    return (
      application.attributes.uses_custom_paperless_terms &&
      customTermsUrl(application.attributes.custom_paperless_terms).url
    );
  },

  hasCustomSupplierTerms() {
    const { application } = this.props;
    return (
      application.attributes.uses_custom_supplier_terms &&
      customTermsUrl(application.attributes.custom_terms).url
    );
  },

  onScrollToBottom() {
    if (!this.state.hasScrolled) {
      this.setState({ hasScrolled: true });
    }

    if (this.state.isTsCsTooltipActive) {
      this.setState({ isTsCsTooltipActive: false });
    }
    setTimeout(this.checkValids, 100);
  },

  renderCheckBoxes() {
    return [
      ...this.renderSupplierCheckboxes(),
      ...[
        isNewTermsAndConditionsEnabled ? [] : this.renderGuarantorCheckboxes(),
      ],
      ...this.renderPaperlessCheckboxes(),
      ...this.renderAntiFraudCheckboxes(),
    ];
  },

  checkBox(name) {
    if (this.validateAllBoxes(name, !this.state[name])) {
      this.setState({ hasCheckedAllBoxes: true });
      this.setState({ showBoxesHint: false });
    } else {
      this.setState({ hasCheckedAllBoxes: false });
    }
    this.setState({ [name]: !this.state[name] });
  },

  renderGuarantorCheckboxes() {
    if (this.props.termList.includes('guarantor')) {
      return isNewTermsAndConditionsEnabled
        ? [this.renderGuarantorTermsCheckbox()]
        : [this.renderGuarantorTermsCheckbox_deprecated()];
    }

    return [];
  },

  isSignatoryTermsAccepted(signatoryTerm, boxName, value) {
    if (!this.props.termList.includes(signatoryTerm)) {
      return [];
    }

    return CHECKBOX_TERMS_BY_SIGNATORY[signatoryTerm].map((checkbox) =>
      checkbox === boxName ? value : this.state[checkbox]
    );
  },

  renderGuarantorTermsCheckbox_deprecated() {
    const supplierName = this.props.application.attributes.supplier_legal_name;
    const label = `I have read and understood the guarantor T&C’s and I
                   acknowledge that ${supplierName} recommend that I obtain
                   independent legal advice as to the effect of this guarantee
                   and my potential liability as guarantor, and I confirm that
                   I have elected not to obtain such independent advice and
                   have agreed to provide the guarantee in favour of the
                   applicant.`;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'guarantor')}
      >
        <SquareCheckbox label={label} checked={this.state.guarantor} />
      </div>
    );
  },

  renderGuarantorTermsCheckbox() {
    const supplierName = this.props.application.attributes.supplier_legal_name;
    const guarantor_liability = `I acknowledge that ${supplierName} recommend that I obtain independent legal advice as to the effect of this Guarantee and my liability as a guarantor`;

    const guarantor_capacity =
      'I acknowledge and agree to the Terms of the Guarantee and/or Indemnity and am signing in my personal capacity as guarantor';

    return (
      <Fragment>
        <div
          className={styles.row}
          onClick={this.checkBox.bind(this, 'guarantor_liability')}
        >
          <SquareCheckbox
            label={guarantor_liability}
            checked={this.state.guarantor_liability}
          />
        </div>
        <div
          className={styles.row}
          onClick={this.checkBox.bind(this, 'guarantor_capacity')}
        >
          <SquareCheckbox
            label={guarantor_capacity}
            checked={this.state.guarantor_capacity}
          />
        </div>
      </Fragment>
    );
  },

  renderAcknowledge() {
    const {
      applicant_guarantor,
      applicantIsCardholder,
      applicantIsDirectDebit,
      application,
      trustApplicantName,
      applicantGuarantorCorporateTrusteeName,
    } = this.props;
    const supplierName = this.props.application.attributes.supplier_legal_name;
    const legalEntityName =
      application.attributes.company_name ||
      application.attributes.consumer_name;

    const items = [];
    if (applicantIsCardholder) {
      items.push(
        `Once you use the ${supplierName} card you are bound by the conditions
          of use set out in the ${supplierName} Terms and Conditions (as amended
          form time to time).`
      );
    }
    if (applicantIsDirectDebit) {
      items.push(
        `I authorise ${supplierName}, until further notice in writing to debit
          my/our account.`
      );
    }
    if (applicant_guarantor) {
      items.push(
        `In electronically signing the Guarantee, you should assume that this
          is a liability you will be called upon to honour, and you should
          satisfy yourself that you have the financial means to meet this
          liability, and are willing to do so. If you are not comfortable with
          that assumption, you should not consent to the Guarantee.`
      );
    }
    return (
      <div className={`${styles.row} mt-3`}>
        <SectionTitle title="Electronic signature" />
        <div className="mb-3">
          {`By electronically signing this section you confirm and acknowledge
          that: You have read and understood this application and the
          information supplied by you is true and complete. ${items.join(' ')}`}
        </div>
        {isNewTermsAndConditionsEnabled && applicant_guarantor && (
          <span>
            {application.attributes.legal_type === 'trust'
              ? `I am signing on behalf of ${
                  applicantGuarantorCorporateTrusteeName || trustApplicantName
                } in my capacity as director and/or shareholder or trustee and also in my personal capacity as guarantor, guaranteeing the performance of the obligations of ${trustApplicantName} under the Terms of the Guarantee.`
              : `I am signing on behalf of ${legalEntityName} in my capacity as director and/or shareholder, and also in my personal capacity as guarantor, guaranteeing the performance of the obligations of ${legalEntityName} under the Terms of the Guarantee.`}
          </span>
        )}
      </div>
    );
  },

  renderAcknowledge_depcrecated() {
    const {
      applicant_guarantor,
      applicantIsCardholder,
      applicantIsDirectDebit,
      application,
    } = this.props;
    const supplierName = this.props.application.attributes.supplier_legal_name;
    const items = [];
    if (applicant_guarantor) {
      items.push(
        <li>
          In electronically signing the guarantee, you should assume that this
          is a liability you will be called upon to honor, and you should
          satisfy yourself that you have the financial means to meet this
          liability, and are willing to do so. If you are not comfortable with
          that assumption, you should not consent to the guarantee.
        </li>
      );
    }
    if (applicantIsCardholder) {
      items.push(
        <li>
          Once you use the {supplierName} card you are bound by the conditions
          of use set out in the {supplierName} Terms and Conditions (as amended
          form time to time).
        </li>
      );
    }
    if (applicantIsDirectDebit) {
      items.push(
        <li>
          I authorise {supplierName}, until further notice in writing to debit
          my/our account.
        </li>
      );
    }
    return (
      <div className={`${styles.row} mt-3`}>
        <SectionTitle title="Signature" />
        <div className="mb-3">
          By signing this section you confirm and acknowledge that: You have
          read and understood this application and the information supplied by
          you is true and complete.
        </div>
        <ul>{items}</ul>
      </div>
    );
  },

  renderAntiFraudCheckboxes() {
    if (this.props.termList.includes('antiFraud')) {
      return [this.renderAntiFraudIdHoldInformation()];
    }

    return [];
  },

  renderPaperlessCheckboxes() {
    if (this.props.termList.includes('paperless')) {
      return [this.renderPaperlessDisclaimer()];
    }

    return [];
  },

  guarantorTerms() {
    const { applicant_guarantor, application, supplier } = this.props;

    if (!applicant_guarantor) {
      return;
    }

    if (this.hasCustomGuarantorTerms()) {
      return customTermsUrl(application.attributes.custom_guarantor_terms).url;
    }

    return TERMS_AND_CONDITIONS[supplier.attributes.region].guarantor;
  },

  renderSupplierCheckboxes() {
    if (this.props.termList.includes('supplier')) {
      return [
        this.renderSupplierTermsCheckbox(),
        this.renderSupplierLegalAgeDeclarationCheckbox(),
        this.renderSupplierCorrectInformationCheckbox(),
        this.renderSupplierDiscloseInformation(),
        this.renderThirdPartyIdCheckConsentCheckbox(),
      ];
    }

    return [];
  },

  paperlessTerms() {
    const { applicantIsDirectDebit, application, supplier } = this.props;

    if (!applicantIsDirectDebit) {
      return;
    }

    if (this.hasCustomPaperlessTerms()) {
      return customTermsUrl(application.attributes.custom_paperless_terms).url;
    }

    return TERMS_AND_CONDITIONS[supplier.attributes.region].paperless;
  },

  renderSupplierLegalAgeDeclarationCheckbox() {
    const label = 'I am not less than 18 years of age';

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'supplierLegalAge')}
      >
        <SquareCheckbox label={label} checked={this.state.supplierLegalAge} />
      </div>
    );
  },

  hideGuarantorConfirmModal() {
    this.setState({ isGuarantorConfirmModalVisible: false });
  },

  render() {
    const {
      application,
      applicant_guarantor,
      combine,
      submitting,
      title,
      isSudoMode,
      isSubmissionBlockedByWatchtower,
    } = this.props;

    const {
      errors,
      first_name,
      guarantorApproved,
      isTsCsTooltipActive,
      last_name,
    } = this.state;

    const renderAgreeRadio = [];

    const supplierName = application.attributes.supplier_legal_name;

    let modal;
    let terms;
    let terms_modal;
    let info;
    let no_top_border;

    const electronic_signature = (
      <div className={`${styles.row} mt-4`}>
        <div className="mb-4">
          If you agree please fill out your first and last name in the fields
          below and press confirm.
        </div>
        <div className={`${styles.inputs_container} mt-3`}>
          <div className={styles.input_container}>
            <TextInput
              id="firstName"
              name="firstName"
              error={errors['firstName']}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label="First name"
              value={first_name}
              required={true}
            />
          </div>
          <div className={styles.input_container}>
            <TextInput
              id="lastName"
              name="lastName"
              error={errors['lastName']}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label="Last name"
              value={last_name}
              required={true}
            />
          </div>
        </div>
      </div>
    );

    terms = this.renderPdfs();

    if (combine && applicant_guarantor) {
      renderAgreeRadio.push(
        <div className={styles.row}>
          <div className={styles.guaranteed_container}>
            {isNewTermsAndConditionsEnabled ? (
              <Fragment>
                <SectionTitle
                  title="Guarantor Acknowledgement and Agreement"
                  style={{ marginLeft: '-5px' }}
                />
                <RadioButtonContainer>
                  <Radiobox
                    id="g-radio"
                    name="g-radio"
                    error={errors['guarantorApproved'] && ' '}
                    handleChange={this.handleGuaranteed}
                    label="Do you agree to guarantee this application?"
                    radioList={['Yes', 'No']}
                  />
                </RadioButtonContainer>
                {(isGuarantorUpdatedTncsEnabled
                  ? this.state.guarantorApproved === 'Yes'
                  : true) && this.renderGuarantorCheckboxes()}
              </Fragment>
            ) : (
              <Fragment>
                <SectionTitle title="Guarantor disclaimers" />
                <Radiobox
                  id="g-radio"
                  name="g-radio"
                  error={errors['guarantorApproved'] && ' '}
                  handleChange={this.handleGuaranteed}
                  label="Do you agree to guarantee this application?"
                  radioList={['Yes', 'No']}
                />
              </Fragment>
            )}
          </div>
        </div>
      );
    }

    return (
      <div>
        <section className={styles.section}>
          <div className={styles.row}>
            <div className={styles.panel}>
              <PanelTitle text={title} />
              <h3 className={styles.info}>{info}</h3>
            </div>
          </div>
          <div className={styles.row_terms}>
            <div className={styles.terms} style={no_top_border}>
              {terms}
            </div>
          </div>
          <div className={styles.row_controls}>
            <PopperTooltip
              title={'Please agree to all the declarations.'}
              placement="bottom-start"
              open={this.state.showBoxesHint}
              noArrow={true}
              PopperProps={{ className: styles.popper_wrapper }}
            >
              <div className={styles.checkbox_container}>
                {isNewTermsAndConditionsEnabled ? (
                  <SectionTitle
                    title="General Acknowledgement and Agreement"
                    style={{ marginLeft: '-5px' }}
                  />
                ) : (
                  <SectionTitle title="General disclaimers" />
                )}
                {this.renderCheckBoxes()}
              </div>
            </PopperTooltip>

            <hr className={styles.divider}></hr>

            {renderAgreeRadio}

            {combine && applicant_guarantor && (
              <hr className={styles.divider} />
            )}

            <ReviewTerms
              {...this.props}
              agreements={this.state.agreements}
              toggleAgreement={this.toggleAgreement}
              isAgreeTooltipActive={this.state.isAgreeTooltipActive}
            />

            <hr className={styles.divider}></hr>

            {isNewTermsAndConditionsEnabled
              ? this.renderAcknowledge()
              : this.renderAcknowledge_depcrecated()}

            {electronic_signature}

            <div className={styles.buttons}>
              {!isSudoMode && (
                <Button
                  text="confirm"
                  loading_text="submitting"
                  disableOnLoading={true}
                  loading={submitting}
                  handleClick={this.handleConfirm}
                />
              )}
              {isTsCsTooltipActive && (
                <ToolTip
                  tip_name="ReviewSupplierTermsScroll"
                  css_style="review_supplier_terms_scroll"
                />
              )}
            </div>

            {isSubmissionBlockedByWatchtower && <WatchtowerBlockMessage />}
          </div>
        </section>
        {modal}
        {terms_modal}
        {this.state.isGuarantorConfirmModalVisible && (
          <GuarantorConfirmModal
            onClose={this.hideGuarantorConfirmModal}
            onConfirm={this.agree}
            supplierName={supplierName}
          />
        )}
      </div>
    );
  },

  // This function returns an array of boolean values wherein all items in the
  // array should be true if the user accepted all the terms for a specific
  // signatory, i.e. applicant, guarantor or paperless
  renderSupplierTermsCheckbox() {
    const supplierName = this.props.application.attributes.supplier_legal_name;
    const oldLabel = `I have read ${supplierName} Terms and Conditions of Sale and agree that all purchases from ${supplierName} will be on those Terms`;

    const newLabel = `I have read ${supplierName} Terms and Conditions of Sale and/or Credit and agree that all purchases from ${supplierName} will be on those Terms`;

    const label = isNewTermsAndConditionsEnabled ? newLabel : oldLabel;
    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'supplier')}
      >
        <SquareCheckbox label={label} checked={this.state.supplier} />
      </div>
    );
  },

  renderAntiFraudIdHoldInformation() {
    const supplierName = this.props.application.attributes.supplier_legal_name;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'antiFraudIdHoldInformation')}
      >
        <SquareCheckbox
          label={`I confirm that ${supplierName} has the right to hold my ID documents on file until my identity has been confirmed`}
          checked={this.state.antiFraudIdHoldInformation}
        />
      </div>
    );
  },

  renderPaperlessDisclaimer() {
    const supplierName = this.props.application.attributes.supplier_legal_name;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'paperless')}
      >
        <SquareCheckbox
          label={`I have read and understood the terms and conditions of the direct debit authority for ${supplierName}`}
          checked={this.state.paperless}
        />
      </div>
    );
  },

  renderSupplierCorrectInformationCheckbox() {
    const supplierName = this.props.application.attributes.supplier_legal_name;
    const label = `I confirm that the information supplied by me is true and
                   complete and may be relied upon by ${supplierName} and their
                   subsidiaries in considering this application`;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'supplierCorrectInformation')}
      >
        <SquareCheckbox
          label={label}
          checked={this.state.supplierCorrectInformation}
        />
      </div>
    );
  },

  renderThirdPartyIdCheckConsentCheckbox() {
    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'thirdPartyCheckConsent')}
      >
        <SquareCheckbox
          label={THIRD_PARTY_CONSENT_LABEL}
          checked={this.state.thirdPartyCheckConsent}
        />
      </div>
    );
  },

  renderSupplierDiscloseInformation() {
    const supplierName = this.props.application.attributes.supplier_legal_name;

    const oldLabel = `I understand that ${supplierName} will not use, store or disclose my information except in accordance with ${supplierName} Privacy Policy and/or Terms and Conditions of Sale`;
    const newLabel = `I understand that ${supplierName} will not use, store or disclose my information except in accordance with ${supplierName} Privacy Policy and/or Terms and Conditions of Sale and/or Credit`;
    const label = isNewTermsAndConditionsEnabled ? newLabel : oldLabel;
    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'supplierDiscloseInformation')}
      >
        <SquareCheckbox
          label={label}
          checked={this.state.supplierDiscloseInformation}
        />
      </div>
    );
  },

  renderPdfs() {
    const pdfs = [
      this.supplierTerms(),
      this.guarantorTerms(),
      this.paperlessTerms(),
    ].filter((pdf) => isPresent(pdf));

    return <PdfReader urls={pdfs} onScrollToBottom={this.onScrollToBottom} />;
  },

  supplierTerms() {
    const { application, supplier } = this.props;

    if (this.hasCustomSupplierTerms()) {
      return customTermsUrl(application.attributes.custom_terms).url;
    }

    return TERMS_AND_CONDITIONS[supplier.attributes.region].supplier;
  },

  validateAllBoxes(boxName, value) {
    if (isGuarantorUpdatedTncsEnabled) {
      const { termList, applicant_guarantor } = this.props;
      let currentTerms = termList;

      if (isNewTermsAndConditionsEnabled && boxName === 'guarantorSection') {
        if (value === 'No') {
          currentTerms = currentTerms.filter((term) => term !== 'guarantor');
        } else if (
          value === 'Yes' &&
          !currentTerms.includes('guarantor') &&
          applicant_guarantor
        ) {
          currentTerms.push('guarantor');
        }
      }

      const isAllTermsAccepted = currentTerms.map((term) =>
        this.isSignatoryTermsAccepted(term, boxName, value)
      );

      return flattenArray(isAllTermsAccepted).every((isAccepted) => isAccepted);
    } else {
      const { termList } = this.props;
      const isAllTermsAccepted = termList.map((term) =>
        this.isSignatoryTermsAccepted(term, boxName, value)
      );

      return flattenArray(isAllTermsAccepted).every((isAccepted) => isAccepted);
    }
  },
});

const generateTrustApplicantName = (application, trustees) => {
  if (application.legal_type !== 'trust') {
    return null;
  }

  const trusteeNamesArray = trustees
    .filter((trustee) => trustee.trustee_type !== 'independent')
    .sort((a, b) => a.trustee_type.localeCompare(b.trustee_type))
    .map((trustee) => {
      const {
        trustee_type,
        corporate_trustee_company_name,
        first_name,
        middle_name,
        last_name,
      } = trustee;

      if (trustee_type === 'corporate') {
        return corporate_trustee_company_name;
      }

      return `${first_name} ${
        middle_name ? `${middle_name} ` : ''
      }${last_name}`;
    });

  const trusteeNames = trusteeNamesArray.join(', ');

  if (trusteeNames) {
    const companyName = application.company_name || application.consumer_name;
    const formattedCompanyName =
      application.region === 'AU'
        ? companyName.replace(/the trustee for/i, 'as the Trustee for')
        : `ATF ${companyName}`;

    return `${trusteeNames} ${formattedCompanyName}`;
  }

  return '';
};

const defaults = {
  title: isNewTermsAndConditionsEnabled ? 'Terms and Conditions' : 'T&Cs',
};

module.exports = connect((state, ownProps) => {
  const guarantors = state.cob_guarantors;
  const isSudoMode = state.current_user.isSudoMode;
  const applicant_guarantor = guarantors.form_values.find(
    (g) => g && g.is_applicant
  );
  const applicantIsDirectDebit =
    state.cob_paperless.answers.applicantAuthorised;
  const applicantIsCardholder = state.cob_cards.cardholders.some(
    (c) => c.attributes && c.attributes.isApplicant
  );
  const applicantSignatory = state.cob_section.current_people.find(
    (p) => (p.attributes || {}).is_applicant
  );

  const termList = ['supplier'];
  if (applicant_guarantor) {
    termList.push('guarantor');
  }
  if (applicantIsDirectDebit) {
    termList.push('paperless');
  }

  const isIdentificationDisclaimerVisible =
    getIsIdentificationDisclaimerVisible();

  if (isIdentificationDisclaimerVisible) {
    termList.push('antiFraud');
  }

  const antiFraudCategoryRules = getApplicableAntiFraudRulesForApplicationFlow({
    antiFraud: new AntiFraudAddonVersionModel(
      get(state, 'cob_section.antiFraud', {})
    ),
    application: new ApplicationModel(
      get(state, 'cob_section.application', {})
    ),
    cards: get(state, 'cob_cards.cardholders', []),
    guarantors: state.cob_guarantors || {},
    paperless: get(state, 'cob_paperless', []),
    people: get(state, 'cob_section.current_people', []),
    requestedLimit: get(state, 'cob_money.requested_limit'),
  });

  const nameComponents = retrieveNameComponents({
    cardholder: state.cob_cards.cardholders.find(
      (card) => card.attributes && card.attributes.isApplicant
    ),
    guarantor: applicant_guarantor,
    identity: state.identity,
    signatory: applicantSignatory,
  });

  const trustees = state.cob_business.entity_party_details_values;
  const trustApplicantName = generateTrustApplicantName(
    ownProps.application.attributes,
    trustees
  );

  let applicantGuarantorType = null;
  let applicantGuarantorCorporateTrustee = null;
  let applicantGuarantorCorporateTrusteeName = null;

  if (applicant_guarantor) {
    applicantGuarantorType =
      applicant_guarantor.position === 'Individual trustee'
        ? 'individual_trustee'
        : 'director';
    applicantGuarantorCorporateTrustee =
      applicantGuarantorType === 'director'
        ? trustees.find(
            (trustee) =>
              trustee.corporate_trustee_id ===
              applicant_guarantor.associated_entity_id
          )
        : null;
    applicantGuarantorCorporateTrusteeName = applicantGuarantorCorporateTrustee
      ? applicantGuarantorCorporateTrustee.corporate_trustee_company_name
      : null;
  }

  return {
    applicantIsCardholder,
    applicantIsDirectDebit,
    applicant_guarantor,
    referenceFirstName: nameComponents.firstName,
    referenceLastName: nameComponents.lastName,
    authorisation_id: state.cob_section.authorisation.id,
    requiresIdentificationSection:
      antiFraudCategoryRules.requiresIdentificationSection,
    combine:
      !!applicant_guarantor ||
      !!applicantSignatory ||
      !!applicantIsCardholder ||
      !!applicantIsDirectDebit,
    entity: state.cob_business,
    query_params: state.current_user.query_params,
    submitting: state.cob_review.submitting,
    supplier: state.cob_section.supplier,
    termList,
    isSudoMode,
    current_entity: state.current_user.current_entity,
    title: defaults.title,
    isIdentificationDisclaimerVisible,
    isSubmissionBlockedByWatchtower:
      state.cob_review.isSubmissionBlockedByWatchtower,
    trustApplicantName,
    applicantGuarantorCorporateTrusteeName,
  };
})(ReviewSupplierTerms);
